import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import {
  ApiChangeEmailData,
  ApiChangePhoneData,
  ApiCustomerCheckPassCodeData,
  ApiSendEmailCodeData,
  ApiSendSmsCodeData,
  ApiUpdateCustomerData,
} from './api.types';
import { normalizeGetToken } from './normalize';
import { SMS_RESULT_CODES } from './constants';

export const Store = types.model().actions(() => ({
  getCustomer: flow(function* request(phone: string) {
    const response = yield requests.getCustomer(phone);
    return response?.data;
  }),

  checkCustomer: flow(function* request(data) {
    const response = yield requests.checkCustomer(data);
    return response?.data;
  }),

  updateCustomer: flow(function* request(data: ApiUpdateCustomerData) {
    const response = yield requests.updateCustomer(data);
    return response?.data;
  }),

  getProfile: flow(function* request() {
    const response = yield requests.getProfile();
    return response?.data;
  }),

  getBalance: flow(function* request() {
    const response = yield requests.getBalance();
    return response?.data || null;
  }),

  sendSmsCode: flow(function* request(data: ApiSendSmsCodeData, smartToken = '', withoutSmartToken = false) {
    const response = yield requests.sendSmsCode(data, smartToken, withoutSmartToken);
    if (response?.data.resultCode !== SMS_RESULT_CODES.SUCCESS) {
      throw new Error('resultCode is not success');
    }
    return response?.data;
  }),

  sendEmailCode: flow(function* request(data: ApiSendEmailCodeData) {
    const response = yield requests.sendEmailCode(data);
    if (response?.data.resultCode !== SMS_RESULT_CODES.SUCCESS) {
      throw new Error('resultCode is not success');
    }
    return response?.data;
  }),

  checkCodeAndUpdate: flow(function* request(data: ApiChangePhoneData) {
    const response = yield requests.checkCodeAndUpdate(data);
    return response?.data;
  }),

  checkCodeAndUpdateEmail: flow(function* request(data: ApiChangeEmailData) {
    const response = yield requests.checkCodeAndUpdateEmail(data);
    return response?.data;
  }),

  deleteCustomer: flow(function* request() {
    const response = yield requests.deleteCustomer();
    return response?.data;
  }),

  getCard: flow(function* request() {
    const response = yield requests.getCard();
    return response?.data?.pan;
  }),

  existCustomerGetToken: flow(function* request(data) {
    const response = yield requests.existCustomerGetToken(data);
    return normalizeGetToken(response?.data);
  }),

  newCustomerGetToken: flow(function* request(data) {
    const response = yield requests.newCustomerGetToken(data);
    return normalizeGetToken(response?.data);
  }),

  checkPassCode: flow(function* request(data: ApiCustomerCheckPassCodeData) {
    const response = yield requests.checkPassCode(data);
    return response?.data;
  }),
}));

export const store = Store.create();

export default store;
