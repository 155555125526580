import { types, Instance } from 'mobx-state-tree';
import { SMS_CODE_VERIFICATION_RESULTS, SMS_RESULT_CODES } from './constants';

export const Delivery = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  patronymic: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
});

export const UserData = types.model({
  cuid: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  emailConfirmed: types.maybeNull(types.boolean),
  phone: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  dob: types.maybeNull(types.string),
  delivery: types.maybeNull(Delivery),
  billing: types.maybeNull(Delivery),
});

export const Balance = types.model({
  balance: types.maybeNull(types.number),
});

export type IUserData = Instance<typeof UserData>;

export const SmsCode = types.model({
  resendNum: types.maybeNull(types.number),
  sessionId: types.maybeNull(types.string),
  verifyNum: types.maybeNull(types.number),
  resultCode: types.maybeNull(types.enumeration(Object.values(SMS_RESULT_CODES))),
  passCodeVerificationResult: types.maybeNull(types.enumeration(Object.values(SMS_CODE_VERIFICATION_RESULTS))),
});

export const CheckCustomer = types.model({
  additionalAuthRequired: types.maybeNull(types.boolean),
  email: types.maybeNull(types.string),
  resendNum: types.maybeNull(types.number),
  sessionId: types.maybeNull(types.string),
  smsResultCode: types.maybeNull(types.string),
  userExist: types.maybeNull(types.boolean),
  verifyNum: types.maybeNull(types.number),
});

export interface ICheckCustomer extends Instance<typeof CheckCustomer> {}

export const ConfirmationCodeVerificationResult = types.model({
  resendNum: types.maybeNull(types.number),
  sessionId: types.maybeNull(types.string),
  verifyNum: types.maybeNull(types.number),
  resultCode: types.maybeNull(types.string),
});

export const Oauth2AccessToken = types.model({
  accessToken: types.maybeNull(types.string),
  refreshToken: types.maybeNull(types.string),
  expiresIn: types.maybeNull(types.number),
  scope: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  sub: types.maybeNull(types.string),
  aud: types.maybeNull(types.string),
  jti: types.maybeNull(types.string),
  approved: types.maybeNull(types.boolean),
  authorities: types.maybeNull(types.array(types.string)),
});

export interface IOauth2AccessToken extends Instance<typeof Oauth2AccessToken> {}

export const GetToken = types.model({
  confirmationCodeVerificationResult: types.maybeNull(ConfirmationCodeVerificationResult),
  oauth2AccessToken: types.maybeNull(Oauth2AccessToken),
});

export interface IGetToken extends Instance<typeof GetToken> {}

export const CheckPassCode = types.model({
  resultCode: types.maybeNull(types.string),
  resendNum: types.maybeNull(types.number),
  verifyNum: types.maybeNull(types.number),
  sessionId: types.maybeNull(types.string),
});

export interface ICheckPassCode extends Instance<typeof CheckPassCode> {}
