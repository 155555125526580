import { URLS } from './constants';

import { getApiInstance } from '../axios';
import { getAuthHeaders } from '../utils';
import {
  ApiChangeEmailData,
  ApiChangePhoneData,
  ApiCheckCustomerParams,
  ApiSendEmailCodeData,
  ApiSendSmsCodeData,
  ApiUpdateCustomerData,
  ApiCustomerGetTokenData,
  ApiCustomerCheckPassCodeData,
} from './api.types';

function getCustomer(phone: string) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: URLS.GET_CUSTOMER.replace(':id', phone),
  });
}

function checkCustomer(params: ApiCheckCustomerParams) {
  return getApiInstance()({
    headers: { ...getAuthHeaders() },
    method: 'POST',
    url: URLS.CHECK_CUSTOMER,
    params,
  });
}

function getProfile() {
  return getApiInstance()({
    headers: getAuthHeaders(true),
    method: 'GET',
    url: URLS.GET_PROFILE,
  });
}

function updateCustomer(data: ApiUpdateCustomerData) {
  return getApiInstance()({
    headers: getAuthHeaders(true),
    method: 'PATCH',
    url: URLS.UPDATE_CUSTOMER,
    data,
  });
}

function getBalance() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: URLS.GET_BALANCE,
  });
}

function getCard() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'GET',
    url: URLS.GET_CARD,
  });
}

function sendSmsCode(data: ApiSendSmsCodeData, smartToken: string, withoutSmartToken = false) {
  let headers: any = {
    ...getAuthHeaders(),
    'smart-tkn': smartToken,
  };
  if (withoutSmartToken) {
    headers = { ...headers, 'x-version': 'true' };
  }
  return getApiInstance()({
    headers: headers,
    method: 'POST',
    url: URLS.SEND_SMS_CODE,
    data,
  });
}

function sendEmailCode(data: ApiSendEmailCodeData) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'POST',
    url: URLS.SEND_EMAIL_CODE,
    data,
  });
}

function checkCodeAndUpdate(data: ApiChangePhoneData) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: URLS.CHECK_CODE_AND_UPDATE,
    data,
  });
}

function checkCodeAndUpdateEmail(data: ApiChangeEmailData) {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'PATCH',
    url: URLS.CHECK_CODE_AND_UPDATE_EMAIL,
    data,
  });
}

function deleteCustomer() {
  return getApiInstance()({
    headers: getAuthHeaders(),
    method: 'DELETE',
    url: URLS.DELETE_CUSTOMER,
  });
}

function existCustomerGetToken(data: ApiCustomerGetTokenData) {
  return getApiInstance()({
    headers: { ...getAuthHeaders() },
    method: 'POST',
    url: URLS.EXIST_CUSTOMER_GET_TOKEN,
    data,
  });
}

function newCustomerGetToken(data: ApiCustomerGetTokenData) {
  return getApiInstance()({
    headers: { ...getAuthHeaders() },
    method: 'POST',
    url: URLS.NEW_CUSTOMER_GET_TOKEN,
    data,
  });
}

function checkPassCode(data: ApiCustomerCheckPassCodeData) {
  return getApiInstance()({
    headers: { ...getAuthHeaders() },
    method: 'POST',
    url: URLS.CHECK_PASS_CODE,
    data,
  });
}

export default {
  getCustomer,
  getProfile,
  checkCustomer,
  updateCustomer,
  getBalance,
  sendSmsCode,
  sendEmailCode,
  checkCodeAndUpdate,
  checkCodeAndUpdateEmail,
  deleteCustomer,
  getCard,
  existCustomerGetToken,
  newCustomerGetToken,
  checkPassCode,
};
