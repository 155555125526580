import PropTypes from 'prop-types';
import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { observer } from 'mobx-react-lite';
import 'globalthis/polyfill';

import { configureApiInstance as configureDadataApiInstance } from 'api/dadata';
import { configureApiInstance as configureMindboxApiInstance } from 'api/mindbox';

import Cookies from 'universal-cookie';
import Alerts from 'alerts/Alerts';
import Cookie from './components/consent/Cookie';
import Pathname from './components/UI/Pathname';
import Loader from './components/UI/Loader';
import ResetPageMetadata from './components/UI/ResetPageMetadata';
import GooglePageTracking from './components/UI/GooglePageTracking';

import Auth from './containers/auth/Auth';

import Error from './pages/other/Error';

import { getCart, addToCart, deleteAllFromCart, setShopizerCartID } from './redux/actions/cartActions';
import { setUser } from './redux/actions/userAction';
import { setIsAuthorized } from './redux/actions/settingsActions';

import { ROUTING } from './routing';
import { useStore } from './store';

function App(properties) {
  const store = useStore();
  const {
    mounted,
    mount,
    unmount,
    update,
    initialized,
    goTo,
    clearGoTo,
    error,
    isOpenedAuth,
    updatePathname,
    isBlue,
    isMtsOnlineChannel,
  } = store;

  const {
    settings,
    strings,
    deleteAllFromCart,
    setUser,
    setIsAuthorized,
    getCart,
    setShopizerCartID,
    addToCart,
    cartItems,
    isLoading,
  } = properties;
  const { isMobileApp } = settings;

  useEffect(() => {
    configureDadataApiInstance({
      baseUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
      token: '75731d457a09b07c8e49b0a2230c4b9fee3376f8',
    });
    configureMindboxApiInstance({
      baseUrl: 'https://api.mindbox.ru/v3/operations',
    });
  }, []);

  useEffect(() => {
    const cart_cookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
    const cookies = new Cookies();
    const cookie = cookies.get(cart_cookie);
    if (cookie) {
      setShopizerCartID(cookie);
    }
    // console.log(window._env_);
    document.documentElement.style.setProperty('--theme-color', window._env_.APP_THEME_COLOR);
    // if(cookies[cart_cookie]) {
    //  console.log('cookie !!! ' + cookies[cart_cookie]);
    //  props.dispatch(setShopizerCartID(cookies[cart_cookie]));
    // }
    properties.dispatch(
      loadLanguages({
        languages: {
          // from merchant supported languages
          ru: require('./translations/russian.json'),
          en: require('./translations/english.json'),
          fr: require('./translations/french.json'),
        },
      }),
    );
  });

  useEffect(() => {
    if (!mounted)
      mount(
        settings,
        {
          deleteAllFromCart,
          setUser,
          setIsAuthorized,
          getCart,
          setShopizerCartID,
          addToCart,
        },
        strings,
        isLoading,
        cartItems,
      );

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  useEffect(() => {
    if (goTo) clearGoTo();
  }, [goTo, clearGoTo]);

  useEffect(() => {
    if (mounted) update(settings, strings, isLoading, cartItems);
  }, [settings, strings, isLoading, cartItems]);

  return (
    <ToastProvider placement="bottom-left" autoDismiss autoDismissTimeout={7000}>
      <BreadcrumbsProvider>
        <Router>
          {goTo && <Redirect to={goTo} push />}
          {!isMobileApp && !isMtsOnlineChannel && <Cookie />}
          <Auth isOpened={isOpenedAuth} />
          <Pathname update={updatePathname} />
          <Suspense
            fallback={
              <div className={`flone-preloader-wrapper ${isBlue && 'flone-preloader-blue'}`}>
                <Loader delay={2} />
              </div>
            }
          >
            {error && <Error error={error} isMobileApp={isMobileApp} captions={strings} />}
            {!error && initialized && (
              <Switch>
                {ROUTING.map((item) => (
                  <Route key={item.id} path={item.path} exact={item.exact} component={item.Component} />
                ))}
              </Switch>
            )}
          </Suspense>
          <ResetPageMetadata strings={strings} />
          <GooglePageTracking />
        </Router>
      </BreadcrumbsProvider>
      <Alerts />
    </ToastProvider>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProperties = (state) => ({
  settings: state.settings,
  cartItems: state.cartData.cartItems,
  isLoading: state.loading.isLoading,
});

const mapDispatchToProperties = (dispatch) => ({
  addToCart: (id, cartId) => {
    dispatch(addToCart(id, cartId));
  },

  deleteAllFromCart: (cartId) => {
    dispatch(deleteAllFromCart(cartId));
  },

  setUser: (data) => {
    dispatch(setUser(data));
  },

  setIsAuthorized: (data) => {
    dispatch(setIsAuthorized(data));
  },

  getCart: (cartID) => {
    dispatch(getCart(cartID));
  },

  setShopizerCartID: (data) => {
    dispatch(setShopizerCartID(data));
  },
});

export default connect(mapStateToProperties, mapDispatchToProperties)(multilanguage(observer(App)));
